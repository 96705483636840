import Head from 'next/head';
import type { AppProps } from 'next/app';
import { ApolloProvider } from '@apollo/client';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { datadogRum } from '@datadog/browser-rum';
import { ConfigProvider, notification } from 'antd';
import { client as apolloClient } from 'api/apollo-client';
import { NavLayout } from '@/components/navLayout/NavLayout';
import { useRouter } from 'next/router';
import { usePathname } from 'next/navigation';

import 'styles/globals.scss';
import 'styles/ag-grid-override.css';
import 'styles/variables.scss';
import 'styles/colors.scss';
import 'styles/custom-menu.scss';
import 'styles/customStyles.scss';
// Quill rich text editor theme.
import 'quill/dist/quill.snow.css';

import type { NotificationType } from '@/types/enums';
import type { PropsWithChildren } from 'react';
import type { ShowNotificationHandlerType } from '@/types/interfaces';

datadogRum.init({
  applicationId: process.env.NEXT_PUBLIC_DATADOG_APPLICATION_ID || '',
  clientToken: process.env.NEXT_PUBLIC_DATADOG_CLIENT_TOKEN || '',
  site: 'datadoghq.com',
  service: process.env.NEXT_PUBLIC_DATADOG_SERVICE || '',
  env: process.env.NEXT_PUBLIC_DATADOG_ENV || '',
  // Specify a version number to identify the deployed version of your application in Datadog
  version: '1.0.0',
  sessionSampleRate: 100,
  sessionReplaySampleRate: 20,
  trackUserInteractions: true,
  trackResources: true,
  trackLongTasks: true,
  defaultPrivacyLevel: 'mask-user-input',
});

function NavLayoutResolver({ children }: PropsWithChildren) {
  const path = usePathname();
  const router = useRouter();

  const excludedPaths = ['/', '/home'];

  if (excludedPaths.includes(path as string) || router.route === '/_error') {
    return <>{children}</>;
  }

  return <NavLayout>{children}</NavLayout>;
}

export default function App({ Component, pageProps }: AppProps) {
  const googleKey = process.env.NEXT_PUBLIC_GOOGLE_AUTH_KEY || '';
  const titlePrefix = process.env.NEXT_PUBLIC_TITLE_PREFIX || '';

  const [messageApi, contextHolder] = notification.useNotification();

  const openNotificationWithIcon: ShowNotificationHandlerType = (
    type: NotificationType,
    message: string,
    config,
  ) => {
    messageApi[type]({ message, placement: 'bottomLeft', ...config });
  };

  const componentProps = {
    ...pageProps,
    contextHolder,
    messageApi,
    showNotification: openNotificationWithIcon,
  };

  return (
    <>
      <Head>
        <title>{titlePrefix + 'GC Data Management Tool'}</title>
      </Head>
      <ApolloProvider client={apolloClient}>
        <GoogleOAuthProvider clientId={googleKey}>
          <ConfigProvider
            theme={{
              token: {
                fontFamily: 'Archivo',
                colorLink: '#7D52CB',
                colorPrimaryBg: '#baa9e9',
                colorPrimary: '#7D52CB',
                colorTextDisabled: '#E9E9F0',
              },
              components: {
                Button: {
                  borderColorDisabled: '#e9e9f0',
                  colorBgContainer: 'none',
                  colorBgContainerDisabled: 'none',
                  defaultBorderColor: '#d0c1f1',
                  defaultColor: '#7D52CB',
                },
                Menu: {
                  itemBg: '#FDFAF3',
                  itemSelectedBg: '#F9F0DB',
                  subMenuItemBg: 'none',
                },
                Tabs: {
                  itemColor: 'rgba(121, 115, 157, 1)',
                  itemSelectedColor: 'black',
                },
              },
            }}
          >
            <NavLayoutResolver>
              <Component {...componentProps} />
            </NavLayoutResolver>
          </ConfigProvider>
        </GoogleOAuthProvider>
      </ApolloProvider>
    </>
  );
}
